import $ from "jquery";
import {elementReady} from "../ReadyListener";
import {animateIconDown, animateIconUp} from "../helpers/animateIcon";

elementReady((root) => {
    const accordions = root.findWithRoot('.c-accordion');
    accordions.each((i,el) => {
        if (!el.setupDone) {
            setupAccordion(el);
            el.setupDone = true;
        }
    })
})

const setupAccordion = (el) => {
    const $comp = $(el);
    const accordionItems = $comp.find('.c-accordion__item');

    accordionItems.each((i, element) => {
        const itemHeader = $(element).find('.c-accordion__item-header');
        const itemContent = $(element).find('.c-accordion__item-content');
        const icon = $(itemHeader).find('i');
        const headline = $(itemHeader).find('p');
        $(itemHeader).click(() => {

            if(!($(itemContent).hasClass('toggling'))){

                if ($(headline).hasClass("font-normal")){
                    $(headline).addClass("font-bold")
                    $(headline).removeClass("font-normal")
                } else {
                    $(headline).removeClass("font-bold")
                    $(headline).addClass("font-normal")
                }

                $(itemContent).addClass('toggling')
                $(itemContent).stop().slideToggle(300,function(){
                    $(itemContent).removeClass('toggling');
                });

                if ($(icon).hasClass('-rotate-180')) {
                    animateIconDown(icon)
                }
                else {
                    animateIconUp(icon)
                }
            }
            
        })
    });

}
