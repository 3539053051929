import $ from "jquery";
import { elementReady } from "../ReadyListener";

elementReady((root) => {
    const bigCheckboxes = root.findWithRoot('.c-big-checkbox');
    bigCheckboxes.each((i,el) => {
        if (!el.setupDone) {
            setupBigCheckbox(el);
            el.setupDone = true;
        }
    })
})

const setupBigCheckbox = (el) => {
    const $comp = $(el);
    const input = $comp.find("input");

    $comp.on('click', function(e) {

        var checked = $(input).is(':checked');

        if(!checked){
            $(input).prop('checked', true);
            $comp.removeClass("opacity-50");
            $comp.removeClass("hover:opacity-75");
        }
        else{
            $(input).prop('checked', false);
            $comp.addClass("opacity-50");
            $comp.addClass("hover:opacity-75");
        }

    })

}
