import $ from "jquery";

const removeRotations = (icon) => {
    for(let c of [...$(icon)[0].classList]) {
        if(c.indexOf("rotate-")!==-1) {
            $(icon)[0].classList.remove(c);
        }
    }
}

export const animateIconDown = (icon) => {
    $(icon).removeClass('-rotate-180')
    $(icon).addClass('-rotate-0')
}
export const animateIconUp = (icon) => {
    $(icon).removeClass('-rotate-0')
    $(icon).addClass('-rotate-180')
}


export const rotate90 = (icon) => {
    removeRotations(icon);
    $(icon).addClass('rotate-90')
}

export const rotate0 = (icon) => {
    removeRotations(icon);
}
