import URI from 'urijs';
import {map, isArray} from 'lodash-es';

export default class URLService2 {

  constructor(url) {
    this.url = url;
    if(!this.url) {
      this.url = URLService2.getCurrentPathWithSearch();
    }
  }

  redirect() {
    window.location.href = this.url;
  }

  static getCurrentPathWithSearch() {
    let currentPath = window.location.pathname;
    let currentSearch = window.location.search;
    return currentPath + currentSearch;
  }

  setQueryParameter(name, value) {
    this.url = URI(this.url).setQuery(name,value).toString();
  }

  addQueryParameter(name, value) {
    this.url =URI(this.url).addQuery(name,value).toString();
  }

  removeQueryParameterWithValue(name, value) {
    this.url = URI(this.url).removeQuery(name,value).toString();
  }

  removeQueryParameter(name) {
    this.url = URI(this.url).removeQuery(name).toString();
  }

  toggleQueryParameter(name, value) {
    let uri = URI(this.url);
    if(uri.hasQuery(name, value, true)) {
      this.url = this.removeQueryParameterWithValue(name, value)
    } else {
      this.url = this.addQueryParameter(name, value)
    }
  }

  getQueryParameterValueAsList(name) {
    let qry = URI(this.url).query(true);

    if(!qry[name]) return [];
    if(!isArray(qry[name])) {
      return [qry[name]]
    }
    return qry[name]
  }

  getQueryParameterValueAsIntList(name) {
    let qry = URI(this.url).query(true);

    if(!qry[name]) return [];
    if(!isArray(qry[name])) {
      return [parseInt(qry[name])]
    }
    let list = qry[name];
    return map(list, (it) => {
      return parseInt(it);
    })
  }

  getQueryParameterAsBool(name) {
    let qry = URI(this.url).query(true);

    if(!qry[name]) {
      return false;
    } else {
      return true;
    }
  }

  getQueryParameterAsInt(name) {
    let qry = URI(this.url).query(true);

    if(typeof qry[name] == 'undefined') {
      return null;
    } else {
      return parseInt(qry[name]);
    }
  }

  getQueryParameterAsString(name, fallback=null) {
    let qry = URI(this.url).query(true);

    if(typeof qry[name] == 'undefined') {
      return fallback;
    } else {
      return ""+qry[name];
    }
  }

  getQueryParameters() {
    let qry = URI(this.url).query(true);
    return qry;
  }
}
