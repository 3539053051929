import $ from 'jquery';

const elementReadyListeners=[];

const documentReadyListeners=[];

export function documentReady(f) {
  // These functions will be executed once the document is ready
  documentReadyListeners.push(f);
}

export function elementReady(f) {
  // We will collect these functions to be able to execute them again if DOM-Content changes (e.g. AJAX content)
  // We will also execute them once after document-ready
  elementReadyListeners.push(f);
}


export function initialExecuteListeners() {
  for(let f of documentReadyListeners) {
    $().ready(()=>{
      f($(document.body));
    });
  }
  
  for(let f of elementReadyListeners) {
    $().ready(()=>{
      f($(document.body));
    });
  }
}

export function executeElementReadyListenersFor(root) {
  for(let f of elementReadyListeners) {
    $().ready(()=>{
      f($(root));
    });
  }
}
