import {disableBodyScroll, enableBodyScroll} from "body-scroll-lock";

export function enableScrollOnlyInElementScrollLock(target) {
    disableBodyScroll(target);
    document.documentElement.style.overflow = "hidden";
}


export function disableScrollOnlyInElementScrollLock(target) {
    enableBodyScroll(target);
    document.documentElement.style.overflow = "";
}
