import $, { event } from "jquery";
import { documentReady, elementReady } from "../ReadyListener";
import Cookies from 'js-cookie'

const COOKIE_SESSION_START="vs";
const COOKIE_SESSION_DURATION_CONVERSION_SENT="vsd";

window.googleAdsQueue = [];
window.isGoogleAdsInitialized = false;
window.googleAnalyticsQueue = [];
window.isGoogleAnalyticsInitialized = false;

export function initGoogle({initAds, initAnalytics}) {

    window.initAds = initAds;
    window.initAnalytics = initAnalytics;

    if(!initAds && !initAnalytics) {
        return;
    }

    const code = `
    <!-- Google Tag Manager -->
    <script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://ts.actidoo.com/actitm.xs?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-W9SQSH9');</script>
    <!-- End Google Tag Manager -->
    `;
    $(document.body).append(code);


    if(initAds) {
        sendGTMEvent("initAdsOptin");
    }

    if(initAnalytics) {
        sendGTMEvent("initAnalyticsOptin");
    }

    sendConsent(initAds, initAnalytics);

    initMehrAls10MinutenTracking();
    setInterval(initMehrAls10MinutenTracking, 1000*60);
}

/* 2024-03-20 Hinweis: Wir reduzieren die 10 Minuten auf drei Minuten.  */
export function initMehrAls10MinutenTracking()  {
    /* Session Dauer tracken für 10-Minuten Conversion */
    let session_start = Cookies.get(COOKIE_SESSION_START, { domain: window.appConfig.COOKIE_DOMAIN });
    let session_duration_conversion_sent = Cookies.get(COOKIE_SESSION_DURATION_CONVERSION_SENT, { domain: window.appConfig.COOKIE_DOMAIN });
    if(!session_start) {
        Cookies.set(COOKIE_SESSION_START, new Date().getTime(), { secure: false, sameSite: 'lax', domain: window.appConfig.COOKIE_DOMAIN });
    } else {
        let session_age = new Date().getTime() - session_start;
        if(!session_duration_conversion_sent && session_age > 3 * 60) {
            Cookies.set(COOKIE_SESSION_DURATION_CONVERSION_SENT, '1', { secure: false, sameSite: 'lax', domain: window.appConfig.COOKIE_DOMAIN });
            sendConversionMehrAls10MinutenAktiv();
        }
    }
}

function sendGTMEvent(eventName, callback) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        'event': "act_"+eventName,
        'eventCallback' : function() {
            try {
                if(callback) {
                    callback();
                }
            } catch(e) {
                console.error(e);
            }
        },
        'eventTimeout': 2000
    });
}

function sendConsent(ads, analytics) {
    window.dataLayer = window.dataLayer || [];
    function gtag() { dataLayer.push(arguments); }
    gtag('consent', 'default', {
      'ad_user_data': ads ? 'granted' : 'granted',
      'ad_personalization': ads ? 'granted' : 'granted',
      'ad_storage': ads ? 'granted' : 'granted',
      'analytics_storage': analytics ? 'granted' : 'granted',
      'wait_for_update': 500,
    });
}  

export const sendConversionNewsletterAngemeldet = (callback)=>sendGTMEvent("NewsletterAngemeldet", callback);
export const sendConversionKontaktformularAbgeschickt = (callback)=>sendGTMEvent("KontaktformularAbgeschickt", callback);
export const sendConversionTerminanfrageGeklickt = (callback)=>sendGTMEvent("TerminanfrageGeklickt", callback);
export const sendConversionMehrAls10MinutenAktiv = (callback)=>sendGTMEvent("MehrAls10MinutenAktiv", callback);
export const sendConversionCaseStudyFormGeoeffnet = (callback)=>sendGTMEvent("CaseStudyFormGeoeffnet", callback);
export const sendConversionCaseStudyFormAbgeschickt = (callback)=>sendGTMEvent("CaseStudyFormAbgeschickt", callback);
export const sendLeistungsuebersichtAngefordert = (callback)=>sendGTMEvent("LeistungsuebersichtAngefordert", callback);
export const sendRueckrufAngefordert = (callback)=>sendGTMEvent("RueckrufAngefordert", callback);

elementReady((root)=>{
    const hints = root.findWithRoot('[data-conversion-hint]').filter(":visible");
    hints.each((i,el)=>{
        if($(el).data("conversion-hint")=="newsletter_success") {
            sendConversionNewsletterAngemeldet();
        }
        if($(el).data("conversion-hint")=="contact_success") {
            sendConversionKontaktformularAbgeschickt();
        }
        if($(el).data("conversion-hint")=="case_study_form") {
            sendConversionCaseStudyFormGeoeffnet();
        }
        if($(el).data("conversion-hint")=="case_study_success") {
            sendConversionCaseStudyFormAbgeschickt();
        }
        if($(el).data("conversion-hint")=="leistungsuebersicht_angefordert") {
            sendLeistungsuebersichtAngefordert();
        }
        if($(el).data("conversion-hint")=="rueckruf_angefordert") {
            sendRueckrufAngefordert();
        }
    })
})

documentReady((root)=>{
    $("body").on('click', '[data-onclick-google-ads-hint]', function(ev) {
        const hint = $(this).data("onclick-google-ads-hint");

        ev.preventDefault();
        ev.stopPropagation();
        
        const continue_click = ()=>{
            setTimeout(()=>{
                window.location.href = $(this).attr("href");
            },500)
        }
        const force_continue_click = ()=>{
            setTimeout(()=>{
                window.location.href = $(this).attr("href");
            },1000)
        }
        force_continue_click(); // Falls GTAG nicht initialisiert ist oder geblockt wird, wollen wir trotzdem weitermachen....

        if(hint == "booking") {
            sendConversionTerminanfrageGeklickt(()=>{continue_click();});
        } else {
            continue_click();
        }

        return false;
    });

});