import $ from "jquery";
import {elementReady} from "../ReadyListener";
import {animateIconDown, animateIconUp} from "../helpers/animateIcon";

elementReady((root) => {
    const tabs = root.findWithRoot('.c-tabs');
    tabs.each((i, el) => {
        if (!el.setupDone) {
            setupTab(el);
            el.setupDone = true;
        }
    })
})

const setupTab = (el) => {
    const $comp = $(el);
    const tab_navigation = $comp.find('.c-tabs__navigation')[0];
    const direction = $comp.data('tab-horizontal-or-vertical');
    const theme = $comp.data('theme').toLowerCase();
    const drop_down = $comp.find('.drop_down');
    // md:text-actidoo-red-primary md:text-actidoo-orange-primary md:text-actidoo-blue-primary
    const font_color = 'md:text-actidoo-' + theme + '-primary';
    const TRANSITION_CLASS = "transition-all duration-200";
    let border = '';
    let padding = "";
    let margin = ""
    const url = new URL(window.location);
    const tabQuery = url.searchParams.get('tab');

    if (direction === "horizontal") {
        border = 'before:absolute before:left-0 before:h-[4px] before:right-0 before:bottom-0 before:bg-actidoo-' + theme + '-primary';
        padding = " md:pb-4 px-2";
        margin = "md:mb-[13px]"
    } else {
        // before:bg-actidoo-red-primary before:bg-actidoo-orange-primary before:bg-actidoo-blue-primary
        border = 'before:absolute before:left-0 before:w-[4px] before:top-0 before:bottom-0 before:bg-actidoo-' + theme + '-primary';
        padding = "md:px-4";
        margin = "";
    }

    $(window).resize((ev) => {
        if ($(window).width() <= 767) {
            $(tab_navigation).hide();
        }
        if ($(window).width() >= 768) {
            $(tab_navigation).show();
            $(tab_navigation).find("div").removeClass("bg-actidoo-gray-100")
        }
    })

    $comp.findWithRoot("> .c-tabs_wrapper > .c-tabs__element").each((i, element) => {
        const id = $(element).data('tab-id');
        const label = $(element).data('tab-label');

        //const spanElement = document.createElement('span');
        //spanElement.id = id;
        //spanElement.innerHTML = label;
        //$(spanElement).addClass('px-2 py-0.5 text-paragraph -ml-[3px] hover:text-actidoo-red-400 md:py-2 md:px-4 md:text-headline-3 md:font-light cursor-pointer')

        const divElement = document.createElement('div');
        $(divElement).addClass(" relative md:-ml-[3px] py-3 md:py-0 px-2 md:px-0 hover:text-actidoo-" + theme + "-primary cursor-pointer " + margin)

        const hiddenElement = document.createElement('span')
        hiddenElement.innerHTML = label;
        $(hiddenElement).addClass("invisible text-paragraph md:text-headline-3 font-black cursor-pointer " + padding)

        const spanElement = document.createElement('span');
        spanElement.id = id;
        spanElement.innerHTML = label;
        $(spanElement).addClass('c-tabs__navigation-item text-paragraph  md:text-headline-3 md:font-light  absolute ' + padding)

        divElement.append(spanElement);
        divElement.append(hiddenElement);

        $(tab_navigation).append(divElement);


        if ((tabQuery && tabQuery === id )|| (!tabQuery && i === 0)) {
            $(element).show()
            setDropLabelToActiveTab(label, drop_down);
            setActiveTabStyle(spanElement, font_color, border)
            if ($(window).width() <= 767){
                $(divElement).addClass("bg-actidoo-gray-100");
            }

        }

        $(divElement).click((ev) => {
            if ($(element).data('tab-id') == spanElement.id) {
                hideAfterClick($comp.find('.c-tabs__element'), element);
                setUnactiveTabStyle($comp.find('.c-tabs__navigation .c-tabs__navigation-item'), font_color, border);
                $(tab_navigation).find('div').each((i, divelement) => {
                    if ($(divelement).find("span")[0].id == spanElement.id){
                        if ($(window).width() <= 767){
                            $(divElement).addClass("bg-actidoo-gray-100");
                        }
                    } else {
                        $(divelement).removeClass("bg-actidoo-gray-100");
                    }
                })

                const url = new URL(window.location);
                url.searchParams.set('tab', spanElement.id);
                window.history.pushState(null, '', url.toString());

                setActiveTabStyle(spanElement, font_color, border);
                if ($(window).width() <= 767){
                    $(divElement).addClass("bg-actidoo-gray-100");
                }
                if (drop_down.is(":visible")) {
                    $comp.find('.c-tabs__navigation').toggle();
                }
                setDropLabelToActiveTab(label, drop_down);
            }
        })

        if ($comp.find('.drop_down').is(":visible")) {
            $(tab_navigation).hide();
        }


    })

    $comp.find('.drop_down').click((ev) => {
        const icons = $comp.find('.drop_down').find('.app-icon__angle-down')
        icons.each((i, el) => {
            $(el).addClass(TRANSITION_CLASS)
            if ($(el).hasClass('-rotate-180')) {
                animateIconDown(el)
            } else {
                animateIconUp(el)
            }

        })
        $(tab_navigation).toggle()
    })

}

const setDropLabelToActiveTab = (label_name, selector) => {
    $(selector).text(label_name);
    $(selector).append("<i class='ml-2 app-icon__angle-down'></i>");
}

const setActiveTabStyle = (selector, font_color, border) => {
    $(selector).addClass(font_color);
    $(selector).removeClass("md:font-light");
    $(selector).addClass("md:font-bold");
    $(selector).addClass(border);
}

const setUnactiveTabStyle = (selector, font_color, border) => {
    $(selector).removeClass(font_color);
    $(selector).removeClass("md:font-bold");
    $(selector).addClass("md:font-light");
    $(selector).removeClass(border);
}

const hideAfterClick = (selector, element) => {
    $(selector).hide();
    $(element).show();
}
