import $ from "jquery";
import {elementReady} from "../ReadyListener";

elementReady((root) => {
    const tileLayout = root.findWithRoot('.c-tile');
    tileLayout.each((i, el) => {
        if (!el.setupDone) {
            setupTileLayout(el);
            el.setupDone = true;
        }
    })
})

const setupTileLayout = (el) => {
    const $comp = $(el);
    const tileHeader = $comp.find('.c-tile__header');
    const tileTiles = $comp.find('.c-tile__tiles');
    const tileNav = tileHeader.find('.c-tile__nav');
    let allTags = {};

    (tileTiles).find('.c-square__tile').each((i, element) => {
        for (let key in $(element).data('tags')) {
            if (!(key in allTags))
            allTags[key] = $(element).data('tags')[key];
        }

    })

    for (let key in allTags){
        const tileNavItem = document.createElement("div");
        tileNavItem.innerHTML = allTags[key];
        $(tileNavItem).attr('id', key)
        $(tileNavItem).addClass("c-tile__nav-item cursor-pointer")
        tileNav.append(tileNavItem)
    }

    $(tileNav).find('.c-tile__nav-item').each((index, element) => {
        $(element).click(() => {
            $(tileTiles).find('.c-square__tile').each((i, tile) =>{
                $(tile).show()
            })
            let id = $(element).attr('id')
            $(tileNav).find('.c-tile__nav-item').each((i, tileNavItem) => {
                $(tileNavItem).removeClass("border-b-2 border-actidoo-red-primary")
            })
            $(tileTiles).find('.c-square__tile').each((i, tile) =>{
                if (id === "all"){
                    $(tile).show()
                }
                else if (!(id in $(tile).data('tags'))){
                    $(tile).hide()
                }
            })
            $(element).addClass("border-b-2 border-actidoo-red-primary")
        })
    })



}
