import $ from "jquery";
import {elementReady, executeElementReadyListenersFor} from "../ReadyListener";

let captchaLibLoaded = false;
const captchaLibLoadedDfd = $.Deferred();

elementReady((root) => {
    const forms = root.findWithRoot('.c-ajaxform');
    forms.each((i,el) => {
        if (!el.setupDone) {
            el.setupDone = true;

            captchaLibLoadedDfd.then(()=>{
                setupAjaxForm(el);
            });

            if(!captchaLibLoaded) {
                $.getScript("https://challenges.cloudflare.com/turnstile/v0/api.js?onload=componentAjaxFormLoadedCaptchaLib&render=explicit")
            }
        }
    })
})

const setupAjaxForm = (el) => {
    const $comp = $(el);
    const $captchaComp = $comp.find(".c-ajaxform__captcha");
    let captchaId = null;
    let captchaRefreshInterval = null;
    const $submitBtn = $comp.find("button[type='submit']")

    if ($captchaComp.length > 0) {
        captchaId = window.turnstile.render($captchaComp[0], {
            sitekey: $captchaComp.data("site-key"),
            theme: $captchaComp.data("theme"),
            callback: function (token) {
                console.log(`Challenge Success ${token}`);
            },
        });
        captchaRefreshInterval = setInterval(()=>{
            window.turnstile.reset(captchaId);
        }, 1000 * 60 * 3);
    }

    $comp.on('submit', function(e) {
        e.preventDefault(); // prevent native submit

        clearInterval(captchaRefreshInterval);
        captchaRefreshInterval = null;

        var formdata = $comp.serializeArray();
        console.log(e)
        console.log(formdata)

        let submitter = e.originalEvent.submitter;
        if(submitter && submitter.getAttribute("name")) {
            formdata.push({name: submitter.getAttribute("name"), value: submitter.getAttribute("value")})
        }
        $submitBtn.prop( "disabled", true );
        $submitBtn.find('.c-btn-loading-spinner').removeClass('hidden')
        $comp.find(".c-ajax-error-msg").remove()

        $.ajax({
            type: $comp.attr("method"),
            url: $comp.attr("action"),
            data: formdata,
            success: function(data) {
                window.turnstile.remove(captchaId);
                captchaId = null;
                const newData = $(data)
                $comp.replaceWith(newData);
                executeElementReadyListenersFor(newData);
            },
            error: function (e) {
                $submitBtn.prop( "disabled", false );
                $submitBtn.find('.c-btn-loading-spinner').addClass('hidden')
                $( "<div class='c-ajax-error-msg text-sm text-actidoo-red-primary'>Es ist leider ein Fehler aufgetreten. Bitte versuchen Sie es erneut.</div>" ).insertBefore( $submitBtn );
            }
        });

    });
}



window.componentAjaxFormLoadedCaptchaLib = ()=>{
    captchaLibLoaded = true;
    captchaLibLoadedDfd.resolve();
}
