import Cookies from 'js-cookie'
import URLService2 from "./url2";

export const setMyTimezoneCookie = (enableRefreshToDetectTimezone) => {
  let cTimezone = Cookies.get('WTZ');
  let myTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const us = new URLService2();

  if(!cTimezone && myTimezone) {
    // Cookie Zeitzone ist nicht gesetzt
    Cookies.set('WTZ', myTimezone, { expires: 365, secure: true, sameSite: 'lax', domain: window.appConfig.COOKIE_DOMAIN });
    if(enableRefreshToDetectTimezone) {
      us.setQueryParameter("tz", "1");
      us.redirect();
    }
  } else if(cTimezone && myTimezone && cTimezone!==myTimezone) {
    // Cookie Zeitzone ist gesetzt, entspricht aber nicht der lokalen Zeitzone --> UPDATE
    if(us.getQueryParameterAsString("tz") !== null) {
      us.removeQueryParameter("tz");
      window.history.replaceState({}, document.title, us.url);
    } else if(us.getQueryParameterAsString("tz") !== "1") {
      Cookies.set('WTZ', myTimezone, { expires: 365, secure: true, sameSite: 'lax', domain: window.appConfig.COOKIE_DOMAIN });
      if(enableRefreshToDetectTimezone) {
        us.setQueryParameter("tz", "1");
        us.redirect();
      }
    }
  } else if(cTimezone && myTimezone && cTimezone===myTimezone) {
    // Cookie Zeitzone ist korrekt gesetzt!, Entferne "tz" aus URL
    if(us.getQueryParameterAsString("tz") !== null) {
      us.removeQueryParameter("tz");
      window.history.replaceState({}, document.title, us.url);
    }
  }
}


