import $ from "jquery";
import { documentReady, elementReady } from "../ReadyListener";
import Cookies from 'js-cookie'
import { initGoogle } from "./component.google_ads";
import { closeModal, unescapeAndOpenModalWithId } from "./component.modal";

class CookieDefinition {

    constructor(id, label) {
        this.id=id;
        this.label=label;
    }

    isAccepted() {
        const allAccepted = true && Cookies.get('all_cookies_accepted', { domain: window.appConfig.COOKIE_DOMAIN });
        const acceptedSet = JSON.parse(Cookies.get('cookies_accepted', { domain: window.appConfig.COOKIE_DOMAIN }) || "[]");

        return allAccepted || acceptedSet.indexOf(this.id)!=-1;
    }
}

const COOKIE_GOOGLE_ADS = new CookieDefinition("google_ads", "Google Ads Conversion Tracking");
const COOKIE_GOOGLE_ANALYTICS = new CookieDefinition("google_analytics", "Google Analytics Conversion Tracking");

let autoOpened = false;

elementReady((root) => {
    const cookie_banner = root.findWithRoot('#cookiequest');
    let foundOne = false;
    cookie_banner.each((i,el) => {
        if (!el.setupDone) {
            setupCookiebanner(el);
            el.setupDone = true;
            foundOne = true;
        }
    })

    if(Cookies.get('cookie_banner_handled') != 'true' && !autoOpened) {
        autoOpened = true;
        unescapeAndOpenModalWithId("cookiequestmodal");
    }    
})

documentReady((body)=>{
    activate();
})

const activate = () => {
    if (COOKIE_GOOGLE_ADS.isAccepted() || COOKIE_GOOGLE_ANALYTICS.isAccepted()) {
        initGoogle({
            initAds: COOKIE_GOOGLE_ADS.isAccepted(),
            initAnalytics: COOKIE_GOOGLE_ANALYTICS.isAccepted(),
        }); 
    }
}

const setupCookiebanner = (el) => {
    const $comp = $(el);

    //checkboxes
    const checkboxStatistics = $comp.find("#checkboxStatistics");
    const checkboxMarketing = $comp.find("#checkboxMarketing");

    var statisticsInputs = $('#statistics').find('input');
    var marketingInputs = $('#marketing').find('input');

    var allInputs = $('#all-cookie-options').find('input');

    //Cookie Options
    const saveBtn = $comp.find(".cookieSaveBtn");
    const rejectBtn = $comp.find(".cookieRejectBtn");
    const acceptallBtn = $comp.find(".cookieAcceptallBtn");
    const personalConfigBtn = $comp.find(".cookiePersonalBtn");

    const cookiePrivacyBtn = $comp.find(".cookiePrivacyBtn");
    const cookieLegalnoticeBtn = $comp.find(".cookieLegalnoticeBtn");

    saveBtn.on('click', function(e) {         
        closeModal();
        Cookies.set('all_cookies_accepted', '', { secure: false, sameSite: 'lax', domain: window.appConfig.COOKIE_DOMAIN });
        Cookies.set('cookie_banner_handled', 'true', { secure: false, sameSite: 'lax', domain: window.appConfig.COOKIE_DOMAIN }); 
        const checkedValues = allInputs.filter("[value!='']:checked").map((i, el) => el.value).toArray();
        const checkedValuesJSON = JSON.stringify(checkedValues);
        Cookies.set('cookies_accepted', checkedValuesJSON, {secure: false, sameSite: 'lax', domain: window.appConfig.COOKIE_DOMAIN }); 
        activate();
    }) 

    rejectBtn.on('click', function(e) {         
        closeModal();
        Cookies.set('all_cookies_accepted', '', { secure: false, sameSite: 'lax', domain: window.appConfig.COOKIE_DOMAIN });           
        Cookies.set('cookie_banner_handled', 'true', { secure: false, sameSite: 'lax', domain: window.appConfig.COOKIE_DOMAIN }); 
        const checkedValuesJSON = JSON.stringify([]);
        Cookies.set('cookies_accepted', checkedValuesJSON, { secure: false, sameSite: 'lax', domain: window.appConfig.COOKIE_DOMAIN }); 
        activate();
    }) 

    acceptallBtn.on('click', function(e) {         
        closeModal();
        Cookies.set('all_cookies_accepted', 'true', { expires: 365, secure: false, sameSite: 'lax', domain: window.appConfig.COOKIE_DOMAIN });
        Cookies.set('cookie_banner_handled', 'true', { expires: 365, secure: false, sameSite: 'lax', domain: window.appConfig.COOKIE_DOMAIN }); 
        activate(); 
    }) 

    personalConfigBtn.on('click', function(e) {         
        $('.option-list').toggleClass('hidden');              
    }) 

    cookiePrivacyBtn.on('click', function(e) {                
    }) 

    cookieLegalnoticeBtn.on('click', function(e) {         
        $comp.find('#checkboxStatistics').prop('checked');    
    }) 

    checkboxStatistics.on('change', function(e) { 
        statisticsInputs.prop('checked', $(this).prop('checked'));

    }) 
    checkboxMarketing.on('change', function(e) {  
        marketingInputs.prop('checked', $(this).prop('checked'));
    }) 

    statisticsInputs.on('change', function(e) { 
        var allChecked = true;
        statisticsInputs.each(function() {
          if (!$(this).prop('checked')) {
            allChecked = false;
          }
        });
        checkboxStatistics.prop('checked', allChecked);
    });

    marketingInputs.on('change', function(e) { 
        var allChecked = true;
        marketingInputs.each(function() {
          if (!$(this).prop('checked')) {
            allChecked = false;
          }
        });
        checkboxMarketing.prop('checked', allChecked);
    });

}
