import $ from "jquery";
import {elementReady, executeElementReadyListenersFor} from "../ReadyListener";
import {animateIconDown, animateIconUp} from "../helpers/animateIcon";

elementReady((root) => {
    const modalScripts = root.findWithRoot('dialog').filter((i,x) => $(x).data("type") == "text/modal");
    modalScripts.each((i, el) => {
        if (!el.setupDone) {
            setupModal(el);
            el.setupDone = true;
        }
    })
})

const setupModal = (el) => {
    const id = el.id;
    const button = $('.c-modal-opener[data-modal-id='+id+"]");
    
    button.on("click", () => {
        unescapeAndOpenModalWithId(id);
    })

    const autoOpen = $(el).data("auto-open");
    if(autoOpen) {
        setTimeout(function() {
            unescapeAndOpenModalWithId(id);
        })
    }
}

export function unescapeAndOpenModalWithId(id) {
    console.log("unescapeAndOpenModalWithId(id)", id);
    const unescaped = $(document).find(`#${id}`).html();
    //const unescaped = $('<div/>').html(escaped).text(); 
    const content = $(unescaped);
    const modal = $(document).find(".c-modal-wrapper")
    openModal(content, modal)   
}

function openModal(content, modal){
    if (content) {
        $("html").addClass("overflow-hidden")
        $(modal).find(".modal-container").html(content)
        $(modal).removeClass("hidden")
        const closeBtn =  $(content).find(".c-modal-close")
        executeElementReadyListenersFor($(modal));
        if(closeBtn && closeBtn.length>0) {
            closeBtn[0].addEventListener("click", () => {
                closeModal();
            })
        }
    }
}

export function closeModal() {
    const modal = $(document).find(".c-modal-wrapper")
    $(modal).find(".modal-container").html('');
    $(modal).addClass("hidden")
    $("html").removeClass("overflow-hidden")
}