import 'vite/modulepreload-polyfill'

import $ from 'jquery'
import Cookies from 'js-cookie'

// Polyfills and Plugins
import './polyfills/jquery-find'
import './polyfills/images'

// Components
// import './components/form'
import './components/component.mainnav';
import './components/component.tab';
import './components/component.stage';
import './components/component.dropdown';
import './components/component.tile_layout';
import './components/component.accordion';
import './components/component.video_player';
import './components/component.slider';
import './components/component.ajaxform';
import './components/component.big_checkbox';
import './components/component.social-preview';
import './components/component.consent-banner';
import './components/component.google_ads';
import './components/component.modal';

// SCSS
import styles from './index.scss';

//helpers
import { initialExecuteListeners } from './ReadyListener'
import { setupCSRF } from './helpers/ajax'
import { setMyTimezoneCookie } from './helpers/timezone'
import './helpers/newsletter';

const defaultConfig = {
    translations: {
        //put id-value pairs
    },
    // auto detect locale
    locale: window.navigator.userLanguage || window.navigator.language,
}

// Hooks to control and react on the js lifecycle
window.appDfd = $.Deferred()
window.$ = $;

const appSetup = function (config) {
    window.appConfig = {
        ...defaultConfig,
        ...config,
    }

    setMyTimezoneCookie(window.appConfig.ENABLE_REFRESH_TO_DETECT_TIMEZONE || false)
    setupCSRF(window.appConfig.CSRF_COOKIE_NAME, window.appConfig.CSRF_VALUE)

    $().ready(() => {
        initialExecuteListeners()

        setTimeout(() => {
            $('body').addClass('app-initialized')
        }, 50)

        window.appDfd.resolve()
    })
}

const appReady = function (f) {
    window.appDfd.then(
        () => {
            f()
        },
        () => {
            throw new Error('error while executing appReady callback')
        },
    )
}

if(window.appConfigurator) {
    appConfigurator(appSetup, appReady);
}
