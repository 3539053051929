import $ from "jquery";

import {elementReady} from "../ReadyListener";

// Slider with slick
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./component.slider.scss";


elementReady(async  (root) => {
    const accordions = root.findWithRoot('.c-slider');
    if(accordions && accordions.length > 0) {
        await import('slick-carousel/slick/slick');
        accordions.each((i,el) => {
            if (!el.setupDone) {
                setupQuoteSlider(el);
                el.setupDone = true;
            }
        })
    }
})

const setupQuoteSlider = (el) => {
    const $comp = $(el);

    $comp.on('init', function(event, slick){
        $comp.removeClass("opacity-0")
    });

    $comp.slick();
}
