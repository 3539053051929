import {elementReady} from "../ReadyListener";

elementReady(async (root) => {
    const socialPreviewContainer = root.findWithRoot('.c-social-preview');
    if(socialPreviewContainer && socialPreviewContainer.length > 0) {
        const React = await import('react');
        const ReactDOMClient = (await import('react-dom/client')).default;
        const Popup = (await import('../lib/social-preview/pages/Popup/Popup')).default;

        socialPreviewContainer.each((i, el) => {
            if (!el.setupDone) {
                el.setupDone = true;

                const root = ReactDOMClient.createRoot(el);
                const app = <Popup/>;
                root.render(app);
            }
        })
    }

})
