import $ from "jquery";
import {debounce} from "lodash-es";
import {documentReady} from "../ReadyListener";
import {disableScrollOnlyInElementScrollLock, enableScrollOnlyInElementScrollLock} from "../helpers/scroll-lock";
import {animateIconDown, animateIconUp, rotate0, rotate90} from "../helpers/animateIcon";

documentReady((root)=>{
    const mainnav = root.findWithRoot('.c-mainnav');
    mainnav.each((i, el)=>{
       if(!el.setupDone) {
           setupMainnav(el);
           el.setupDone = true;
       }
    });
    const mainnavBurger = root.findWithRoot('.c-mainnav__burger-btn');
    if(!mainnavBurger.setupBurgerDone) {
        setupBurgerNav(mainnavBurger);
        mainnavBurger.setupBurgerDone = true;
    }
    const menuPoint = root.findWithRoot('.c-mainnav__menupoint');
    const submenus = root.findWithRoot('.c-mainnav__submenupoint'); 
    menuPoint.each((i, el)=>{
        if(!el.setupDone) {
            setupMenuPoint(el, menuPoint, submenus);
            el.setupDone = true;
        }
     });
})

const TRANSITION_CLASS="transition-all duration-200";

const setupMenuPoint = (el, menuPoint, submenus) => {
    const $comp = $(el);

    $comp.on('click', function(e) {
        const id = $comp.attr('id');
        var indices = id.split("-"); 
        var l1page_loop_index = indices[1]; 
        var l2page_loop_index = indices[2]; 

        var submenuId = "submenuitem-" + l1page_loop_index + "-" + l2page_loop_index;
        var submenu = document.getElementById(submenuId);

        if ($comp.hasClass("bg-actidoo-gray-300")) {
            $comp.removeClass("bg-actidoo-gray-300");
            submenu.classList.add("hidden");
        } else {            
            menuPoint.each((i, el)=>{
                if (el.classList.contains("bg-actidoo-gray-300")) {
                    el.classList.remove("bg-actidoo-gray-300");
                }
            });
            submenus.each((i, el)=>{
                if (!el.classList.contains("hidden")) {
                    el.classList.add("hidden");
                }
            });
            
            $comp.addClass("bg-actidoo-gray-300");
            submenu.classList.remove("hidden");
        }
    })
}

const setupMainnav = (el) => {
    const $comp = $(el);
    let isSticky = false;
    let isL2Open = false;

    const top = $comp.find(".c-mainnav__top");
    const logo = $comp.find(".c-mainnav__logo");
    const popup_logo = $comp.find(".c-mainnav__popup-logo");
    const popup_logo_subline = $comp.find(".c-mainnav__logo-subline");
    const is_top_white = $comp.hasClass("c-mainnav__white");

    const allTriggers = $comp.findWithRoot("[data-page-id]");
    const allL2Containers = $comp.findWithRoot("div[data-l2-container-for-l1-id]");

    // To handle Sticky deactivation, we need to find out which classes were assigned before sticky-mode!
    let borderWithoutSticky = $comp.hasClass("after:h-[1px]");
    let bgwhiteWithoutSticky = top.hasClass("bg-white");

    const enablePopupStyle = () => {
        // Die Hauptleiste wird weiß gefärbt (geöffnet oder sticky Zustand)
        if(!bgwhiteWithoutSticky) {
            top.addClass("bg-white");
            allTriggers.addClass("shadow-[inset_0px_2px_0px_white]");
        }
        if(is_top_white) {
            $comp.removeClass("text-white");
        }
        $comp.addClass("text-actidoo-gray-800 ");
        popup_logo_subline.addClass("text-actidoo-gray-800");
        popup_logo.show();
        logo.hide();
    }

    const disablePopupStyle = () => {
        if(!bgwhiteWithoutSticky) {
            top.removeClass("bg-white");
            allTriggers.removeClass("shadow-[inset_0px_2px_0px_white]");

        }
        if(is_top_white) {
            $comp.addClass("text-white");
        }
        $comp.removeClass("text-actidoo-gray-800");
        popup_logo_subline.removeClass("text-actidoo-gray-800");

        popup_logo.hide();
        logo.show();
    }

    const showBorder = () => {
        $comp.removeClass("after:content-[none]");
    }

    const hideBorder = () => {
        $comp.addClass("after:content-[none]");
    }

    const toggleBorderForSticky = ()=> {
        if(isSticky) {
            if(!isL2Open) {
                showBorder();
                if (!borderWithoutSticky) { // Es ist noch keine Border gesetzt; Für Sticky brauchen wir das!
                    $comp.addClass("after:h-[2px]");
                    $comp.removeClass("after:h-[0px]");
                }
            }
        } else {
            if(!isL2Open) {
                if (!borderWithoutSticky) { // Ohne Sticky soll es keine Border geben
                    hideBorder();
                    $comp.removeClass("after:h-[2px]");
                    $comp.addClass("after:h-[0px]");
                }
            }
        }
    }

    const handleStickyScroll = () => {
        const scroll = $(window).scrollTop();

        if (scroll >= 32 && !isSticky) {
            // Stick an!
            isSticky=true;

            if(!isL2Open) {
                enablePopupStyle();
                toggleBorderForSticky();
            }
            $comp.addClass("!top-0");

        } else if(scroll < 32 && isSticky) {
            // Stick aus!
            isSticky=false;
            toggleBorderForSticky();
            $comp.removeClass("!top-0");

            if(!isL2Open) {
                disablePopupStyle();
            }
        }
    }

    const handleStickyScrollDebounced = debounce(function() {
        handleStickyScroll();
    }, 10, {leading: true, trailing: true});


    $(window).scroll(function(){
        handleStickyScrollDebounced();
    });
    handleStickyScroll();

    $comp.findWithRoot("[data-page-id]").each((i, triggerEl)=>{
        const l2Container = $comp.findWithRoot("div[data-l2-container-for-l1-id="+$(triggerEl).data("page-id")+"]");
        const l2Links = l2Container.find("a");
        const defaultPreviewText = l2Container.data("preview-text");
        const defaultPreviewTitle = l2Container.data("label");
        const previewTextContainer = l2Container.findWithRoot(".c-mainnav__preview-text");
        const previewTitleContainer = l2Container.findWithRoot(".c-mainnav__preview-title");
        const otherl2Containers = allL2Containers.not(l2Container);
        const otherTriggers = allTriggers.not(triggerEl);
        let mouseOnTargetOrSubmenu = false;
        let mouseOnAnyTargetOrSubmenu = false;
        let mouseOnComponent = false;

        const isAnyItemOpened = () => {
            return allL2Containers.filter(".min-h-64").length>0;
        }

        const closeItem = () =>{
            isL2Open = false;
            l2Container.addClass(TRANSITION_CLASS);
            l2Container.addClass("h-0 opacity-0");
            l2Container.removeClass("min-h-64 opacity-100");
            $(triggerEl).removeClass("bg-actidoo-gray-100");
            $(triggerEl).removeClass("text-actidoo-gray-800");
            $(triggerEl).addClass("text-inherit");

            if(!mouseOnComponent && !isSticky) {
                disablePopupStyle();
            }

            setTimeout(()=>{
                showBorder();
                toggleBorderForSticky();
            }, 240);
        }

        const closeDelayed = debounce(function() {
            if(!mouseOnComponent) {
                closeItem();
            }
        }, 140);

        const openItem = () => {
            isL2Open = true;
            enablePopupStyle();

            if(l2Container.length>0) {
                hideBorder();
            } else {
                showBorder();
            }

            if(!isAnyItemOpened()) {
                l2Container.addClass(TRANSITION_CLASS);
            }

            l2Container.addClass("min-h-64 opacity-100");
            l2Container.removeClass("h-0 opacity-0");

            $(triggerEl).addClass("bg-actidoo-gray-100");
            $(triggerEl).addClass("text-actidoo-gray-800");
            $(triggerEl).removeClass("text-inherit");

            // close other items
            otherl2Containers.removeClass(TRANSITION_CLASS);
            otherl2Containers.removeClass("min-h-64 opacity-100");
            otherl2Containers.addClass("h-0 opacity-0");

            otherTriggers.removeClass("bg-actidoo-gray-100");
            otherTriggers.removeClass("text-actidoo-gray-800");
            otherTriggers.addClass("text-inherit");
        }


        $(document).on('mouseover', (ev)=>{
            const onAnyTargetOrSubmenu = $(allTriggers).findWithRoot($(ev.target)).length>0 || $(allL2Containers).findWithRoot($(ev.target)).length>0;
            const onComponent = $comp.findWithRoot($(ev.target)).length>0;
            const onTarget = $(triggerEl).findWithRoot($(ev.target)).length>0;
            const onSubmenu = $(l2Container).findWithRoot($(ev.target)).length>0;
            const onLink = $(ev.target).closest($(l2Links).add(triggerEl));

            mouseOnTargetOrSubmenu = onTarget || onSubmenu;
            mouseOnAnyTargetOrSubmenu = onAnyTargetOrSubmenu;
            mouseOnComponent = onComponent;

            if(onTarget) {
                // mouse is on target
                openItem();
            }
            if(!mouseOnTargetOrSubmenu) {
                // mouse left target and sub container
                closeDelayed();
            }
            if(onLink.length) {
                const previewText = onLink.data("preview-text");
                const previewTitle = onLink.data("label");
                previewTitleContainer.text(previewTitle);
                previewTextContainer.text(previewText);
            } else {
                previewTitleContainer.text(defaultPreviewTitle);
                previewTextContainer.text(defaultPreviewText);
            }
        });



    });


}

const setupBurgerNav = (el) => {
    const burgerBtn = $(".c-mainnav__burger-btn");
    const burgerCloseBtn = $(document.body).find(".c-mainnav__burger-close-btn");
    const burgerMainContainer = $(document.body).find(".c-mainnav__burger-main");

    burgerBtn.on('click', ()=>{
        console.log("click")
        burgerMainContainer.removeClass("left-full");
        burgerMainContainer.addClass("left-0");
        enableScrollOnlyInElementScrollLock(burgerMainContainer[0]);
    })

    burgerCloseBtn.on('click', ()=>{
        burgerMainContainer.removeClass("left-0");
        burgerMainContainer.addClass("left-full");
        disableScrollOnlyInElementScrollLock(burgerMainContainer[0]);
    });

    const accordions = burgerMainContainer.find(".c-mainnav__burger-accordion");
    const accordionActiveClass = "c-mainnav__burger-accordion--expanded";
    accordions.each((i, accEl) => {
        const $accComp = $(accEl);

        const $accTrigger = $accComp.find("> .c-mainnav__burger-accordion-trigger");
        const $accIcon = $accTrigger.find("> .c-mainnav__burger-accordion-icon");
        const $accContent = $accComp.find("> .c-mainnav__burger-accordion-content");

        $accTrigger.click(()=>{
            if($accComp.hasClass(accordionActiveClass)) {
                $accComp.removeClass(accordionActiveClass);
                rotate0($accIcon);
                $accContent.removeClass("opacity-100");
                $accContent.addClass("opacity-0");
                $accContent.slideUp(260,"linear");
            } else {
                $accComp.addClass(accordionActiveClass);
                rotate90($accIcon);
                $accContent.removeClass("opacity-0");
                $accContent.addClass("opacity-100");
                $accContent.slideDown(260,"linear");
            }

        });
    })

}
