import $ from "jquery";
import {elementReady} from "../ReadyListener";
import {enableScrollOnlyInElementScrollLock, disableScrollOnlyInElementScrollLock} from "../helpers/scroll-lock"
import "video.js/src/css/video-js.scss";

elementReady(async (root) => {
    const videoPlayer = root.findWithRoot('.c-video-player__container');
    if(videoPlayer && videoPlayer.length > 0) {
        let videojsObj = await import('video.js');
        let videojs = videojsObj.default;

        videoPlayer.each((i, el) => {
            if (!el.setupDone) {
                setupVideoPlayer(el, videojs);
                el.setupDone = true;
            }
        })
    }

})

const setupVideoPlayer = async (el, videojs) => {

    const $comp = $(el);
    const playerElement = $comp.find("video").get(0);
    const autoplay = playerElement.autoplay;

    if (!autoplay) {

        const modalContainer = document.createElement("div");
        $(modalContainer).addClass("c-modal__container w-full h-full hidden");
        $("body").append(modalContainer);
        $(playerElement).clone().appendTo(modalContainer);

        const clonedPlayerElement = $(modalContainer).find("video").get(0)
        $(clonedPlayerElement).addClass("vjs-default-skin vjs-big-play-centered")
        const player = videojs(clonedPlayerElement, {'controls': true});
        const origPlayer = videojs(playerElement, {'controls': true});

        $(playerElement).on('play', () => {
            if ($(window).width() <= 767) {
                $(modalContainer).removeClass("hidden")
                player.requestFullscreen();
                player.play();
                origPlayer.pause();
            } else {
                enableScrollOnlyInElementScrollLock($('body'))
                $(modalContainer).removeClass("hidden")
                $(modalContainer).addClass("!fixed !top-1/2 !left-1/2 !-translate-y-1/2 !-translate-x-1/2 flex place-items-center justify-center bg-black/[.85] z-10");
                $(modalContainer).children().addClass("w-7/12 h-4/6");
                player.play()
                origPlayer.pause();
            }
        });

        $(document).click(function (event) {
            if (modalContainer == event.target) {
                disableScrollOnlyInElementScrollLock($('body'))
                if ($(window).width() >= 768) {
                    $(modalContainer).removeClass("!fixed !top-1/2 !left-1/2 !-translate-y-1/2 !-translate-x-1/2 flex place-items-center justify-center bg-black/[.85] z-10");
                    $(modalContainer).addClass("hidden")
                    player.pause();
                    origPlayer.currentTime(player.currentTime());
                }
            }

        });

        document.addEventListener("fullscreenchange", function () {
            if (document.fullscreenElement == null) {
                player.pause()
                origPlayer.currentTime(player.currentTime());
            }
        }, false);

    }


}
