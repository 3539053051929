import $ from "jquery";
import {elementReady} from "../ReadyListener";

elementReady((root) => {
    const form = root.findWithRoot('.newsletter')
    form.each((i, el) => {
        if (!el.setupDone) {
            setupForm(el);
            el.setupDone = true;
        }
    })
})

const setupForm = (el) => {
    const $comp = $(el);
    const button = $comp.find("button");
    const emailInput = $comp.find(".c-input");
    const checkbox = $comp.find(".c-checkbox");    

    $(button).on('click', (ev) => {

        ev.preventDefault();

        let checkedStatus = "";

        if ($(checkbox).is(":checked")) {
            checkedStatus = "1";
        }

        $.ajax({
            type: 'POST',
            url: "/de/identity-management",
            data: {email:$(emailInput).val(), confirmed:checkedStatus},
            success: function(){  
            }
        });
    })

}

