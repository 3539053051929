import $ from "jquery";
import {elementReady} from "../ReadyListener";
import {animateIconDown, animateIconUp} from "../helpers/animateIcon";

elementReady((root) => {
    const dropdowns = root.findWithRoot('.c-dropdown')
    dropdowns.each((i, el) => {
        if (!el.setupDone) {
            setupDropdown(el);
            el.setupDone = true;
        }
    })
})

const setupDropdown = (el) => {
    const $comp = $(el);
    const theme = $comp.data("theme");
    const hoverTextColor = `hover:text-actidoo-${theme.toLowerCase()}-primary`;
    const dropdownHead = $comp.find('.c-dropdown__head');
    const dropdownOptions = $comp.find('.c-dropdown__options')
    const hiddenInput = $comp.find(".hidden-input")
    const selectedOption = $comp.find('.c-dropdown__selected')
    let initialLabel = selectedOption.text().trim();
    const initialValue = hiddenInput.val();
    const label = $comp.find('.c-dropdown__label')
    const borderColor = "border-actidoo-gray-300"
    const borderFocusColor = "border-black";
    const backgroundColorPicked = "bg-actidoo-gray-100"
    const fontColorPicked = "text-black"
    const hoverBackground = "hover:bg-actidoo-gray-100"

    const canBeEmpty = ($(dropdownHead).data('allowed-empty') === 'true' || $(dropdownHead).data('allowed-empty') === 'True');

    const initialOption = document.createElement('div');

    if (!initialLabel && canBeEmpty) {
        initialLabel = "--";
        $(initialOption).addClass("p-3 border-b border-actidoo-gray-300 c-dropdown__option last:border-b-0 text-actidoo-gray-300");
    }

    $(initialOption).text(initialLabel);

    if (initialValue) {
        $(initialOption).attr("data-option-value", initialValue);
        $(initialOption).addClass("p-3 border-b border-actidoo-gray-300 c-dropdown__option last:border-b-0 ");
    }

    if (canBeEmpty) {
        dropdownOptions.prepend(initialOption)
    }

    if (!canBeEmpty) {
        setDropHeaderText($(dropdownOptions).find(".c-dropdown__option").first().text().trim(), $(dropdownHead).find(".c-dropdown__selected"))
    }

    $(dropdownOptions).find(".c-dropdown__option").each((index, element) => {
        if (selectedOption.text().trim() === $(element).text().trim()) {
            $(element).addClass(backgroundColorPicked)
            $(element).addClass(fontColorPicked)
        }
    })

    $(dropdownOptions).find('.c-dropdown__option').each((i, element) => {
        if (i != 0)
            $(element).addClass(hoverTextColor);
    })

    $(document).click(function (event) {
        if (!$(event.target).is(dropdownHead)) {
            closeDropDown(dropdownHead, dropdownOptions, borderFocusColor, borderColor)
        }
    });
    $(dropdownHead).click((ev) => {
        toggleDropDown(dropdownHead, dropdownOptions, borderFocusColor, borderColor);
    })
    $(dropdownOptions).find(".c-dropdown__option").each((index, element) => {
            $(element).click((ev) => {

                $(dropdownOptions).find('.c-dropdown__option').each((i, element) => {
                    $(element).removeClass(backgroundColorPicked);
                    if ($(element).text() != "--") {
                        $(element).addClass(hoverTextColor);
                        $(element).addClass(hoverBackground);
                    }
                })

                if (!$(element).data("option-value")) {
                    setDropHeaderText("", $(dropdownHead).find(".c-dropdown__selected"))
                    $(label).removeClass("text-[10px] -translate-y-3/4")
                } else {
                    setDropHeaderText(element.innerText, $(dropdownHead).find(".c-dropdown__selected"))
                    $(label).addClass("text-[10px] -translate-y-3/4");
                    $(element).addClass(backgroundColorPicked)
                    $(element).addClass(fontColorPicked)
                }
                hiddenInput.val($(element).data("option-value"))
                toggleDropDown(dropdownHead, dropdownOptions, borderFocusColor, borderColor);
                $(element).removeClass(hoverTextColor)
                $(element).removeClass(hoverBackground);
            })
        }
    )
}


const closeDropDown = (dropdownHead, dropdownOptions, focusColor, borderColor) => {
    $(dropdownOptions).hide();
    $(dropdownHead).removeClass(focusColor)
    $(dropdownHead).addClass(borderColor)
    const angleDown = $(dropdownHead).find(".app-icon__angle-down")

    animateIconDown(angleDown)

}

const openDropDown = (dropdownHead, dropdownOptions, focusColor, borderColor) => {
    $(dropdownOptions).show();
    $(dropdownHead).addClass(focusColor)
    $(dropdownHead).removeClass(borderColor)
    const angleDown = $(dropdownHead).find(".app-icon__angle-down")

    animateIconUp(angleDown)

}

const toggleDropDown = (dropdownHead, dropdownOptions, focusColor, borderColor) => {
    if ($(dropdownOptions).is(":visible")) {
        closeDropDown(dropdownHead, dropdownOptions, focusColor, borderColor)
    } else {
        openDropDown(dropdownHead, dropdownOptions, focusColor, borderColor)
    }
}

const setDropHeaderText = (text, selector) => {
    $(selector).text(text)
}


